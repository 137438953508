<template>
  <data-list
    ref="dataList"
    class="content md"
    :url="url"
    query="&sort=-id"
    entity-name="图标"
    add-btn="新增图标"
    hide-query
    hide-show-detail
    :rules="rules"
    :columns="columns"
    :action="action"
  >
    <template v-slot:edit="{ form }">
      <a-form-model-item label="图标名称" prop="name">
        <a-input v-model="form.name" placeholder="请输入图标名称（最多10字）" :maxLength="10" allowClear></a-input>
      </a-form-model-item>
      <a-form-model-item label="上传图标" prop="img">
        <form-upload v-model="form.img" uploadTip="建议图标尺寸160*176，大小不超过2M"></form-upload>
      </a-form-model-item>
    </template>
  </data-list>
</template>

<script>
export default {
  name: "NicheIcon",
  data() {
    return {
      url: "/admin/mini-worship-type",
      columns: [
        { title: "图标名称", dataIndex: "name" },
        {
          title: "图标",
          dataIndex: "img",
          customRender: (text) => {
            return <img class="avatar" src={text} />;
          },
        },
      ],
      rules: {
        name: [{ required: true, message: "请输入区域名称", trigger: "blur" }],
        img: [{ required: true, message: "请上传图标", trigger: "change" }],
      },
    };
  },
  computed: {
    action() {
      return this.$route.meta.action;
    },
  },
  methods: {},
};
</script>

<style lang="less" scoped></style>
